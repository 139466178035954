import React, {useEffect, useState} from 'react';
import Popup from 'reactjs-popup';

import api from '../../resources/api';

import './Portfolio.css';

export default function Portfolio () {
    const [videos, setVideos] = useState([])

    useEffect(() => {
            api.get('playlistItems?part=snippet&maxResults=6&playlistId=UUKhT54xsBxHlc_Z7JTtrTTw&key=AIzaSyCwvTVlc3iA0L-De5Xi046TUgoPEzwbi70')
            .then(response => setVideos(response.data.items));
    }, []);

    return (
        <>
            <div className="portfolio-container">
                <h2 className="portfolio-title" id="portfolio-link">Projetos</h2>
                <div className="videos-container">
                    <div className="videos-section">
                        {videos.map(video => (
                            <Popup key={video.snippet.resourceId.videoId} trigger={<img src={`https://img.youtube.com/vi/${video.snippet.resourceId.videoId}/hqdefault.jpg`} alt="Video" className="videos-thumbnail"/>} position="top center" className="popup-videos" arrow={false} modal lockScroll={true}>
                                <iframe title="PPTyqzM253Q" type="text/html" className="videos" src={`https://www.youtube.com/embed/${video.snippet.resourceId.videoId}?controls=0`}frameborder="0"/>
                            </Popup>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
