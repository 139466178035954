import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { BrowserRouter } from 'react-router-dom';
import './Main.css';

import white_logo from '../../assets/logo_branco.png';
import email from '../../assets/email.png';
import instagram from '../../assets/instagram.png';
import black_logo from '../../assets/logo_preto.png';

export default function Main() {
    return (
        <>
            <div className="main-header">
                <div className="header-logo-container">
                    <BrowserRouter>
                        <Link smooth={true} to="/Main/Main#main-link"><img src={black_logo} alt="Panacota" className="header-logo"/></Link>
                    </BrowserRouter>
                </div>
                <div className="header-buttons-container">
                    <BrowserRouter>
                        <Link smooth={true} to="../About/About#about-link" className="menu-buttons">Sobre</Link>
                        <Link smooth={true} to="../Portfolio/Portfolio#portfolio-link" className="menu-buttons">Projetos</Link>
                        <Link smooth={true} to="../Contact/Contact#contact-link" className="menu-buttons" id="contact">Contato</Link>
                    </BrowserRouter>
                </div>
                <div className="header-icons-container">
                    <a href="mailto:brvcontato@panacotavideos.com.br" target="_blank" rel="noopener noreferrer" className="header-icon"><img src={email} alt="Email" className="email-icon"/></a>
                    <a href="https://www.instagram.com/panacotavideos/" target="_blank" rel="noopener noreferrer" className="header-icon"><img src={instagram} alt="Instagram" className="instagram-icon"/></a>
                </div>
            </div>
            <div className="main-container" id="main-link">
                <div className="logo-container">
                    <img src={white_logo} alt="Panacota" className="logo"/>
                </div>
            </div>
        </>
    );
}



