import React from 'react';

import './App.css';

import Main from './pages/Main/Main';
import About from './pages/About/About';
import Services from './pages/Services/Services';
import Portfolio from './pages/Portfolio/Portfolio';
import Contact from './pages/Contact/Contact';



function App() {
  return (
    <>
      <Main/>
      <div className="pages-separation"/>
      <About/>
      <Services/>
      <Portfolio/>
      <Contact/>
    </>
  );
}

export default App;
