import React, {useState, useRef, useLayoutEffect} from 'react';

import './Services.css';

import creation from '../../assets/criacao.svg';
import editing from '../../assets/edicao.svg';
import production from '../../assets/producao.svg';


export default function Services () {
    const animationRef = useRef();
    const [animation, setAnimation] = useState(false);
    useLayoutEffect(() => {
        const topPos = element => element.getBoundingClientRect().bottom;
        const divPos = topPos(animationRef.current);

        const onScroll = () => {
            const scrollPos = window.scrollY + window.innerHeight;
            if(divPos < scrollPos){
                setAnimation(true);
            }
        };
        window.addEventListener("scroll", onScroll);
    }, []);
    return (
        <div className="services-container" ref={animationRef}>
            <img src={creation} alt="Criação" className="services" id={animation ? "creation" : null}/>
            <img src={production} alt="Produção" className="services" id={animation ? "production" : null}/>
            <img src={editing} alt="Edição" className="services" id={animation ? "editing" : null}/>
        </div>
    );
}
