import React, {useState, useRef, useLayoutEffect} from 'react';

import './About.css';

import bruno from '../../assets/bruno.gif';
import juliana from '../../assets/juliana.gif';
import vanessa from '../../assets/vanessa.gif';
import team from '../../assets/equipe.png';



export default function About (){
    const animationRef = useRef();
    const [animation, setAnimation] = useState(false);
    useLayoutEffect(() => {
        const topPos = element => element.getBoundingClientRect().top;
        const divPos = topPos(animationRef.current);

        const onScroll = () => {
            const scrollPos = window.scrollY + window.innerHeight;
            if(divPos < scrollPos){
                setAnimation(true);
            }
        };
        window.addEventListener("scroll", onScroll);
    }, []);
    return (
        <>
            <div className="gif-stripe">
                <img src={juliana} alt="Juliana" className="gifs"/>
                <img src={bruno} alt="Bruno" className="gifs"/>
                <img src={vanessa} alt="Vanessa" className="gifs"/>
            </div>
            <h2 className="about-title" id="about-link">Sobre</h2>
            <div className="about-page-container">
                <div className="about-text-container">
                    <p className="about-text">
                    A <p className="about-text-bold">Panacota Vídeos</p> é um produtora de conteúdo audiovisual criada para você, que quer colocar em prática aquela ideia que não sai da sua cabeça! :)
                    <br/>
                    <br/>
                    Te proporcionamos ajuda desde o início da sua ideia: brainstorm, roteiro, produção, filmagem, edição e a entrega do produto final.
                    <br/>
                    <br/>
                    Quer colocar a sua ideia em prática?
                    <br/>
                    <br/>
                    Entre em contato com a gente!
                    </p>
                </div>
                <div ref={animationRef} className="about-image-container">
                    <img src={team} alt="Equipe Panacota" className="about-image" id={animation ? "about-image-slide" : null}/>
                </div>
            </div>
        </>
    );
}
