import React from 'react';
import emailjs from 'emailjs-com';
import { useAlert } from 'react-alert';

import './Contact.css';

export default function Contact () {
    const alert = useAlert();
    function sendEmail (e){
        e.preventDefault();
        emailjs.init('user_6nv9PwoPcUSFo4Kq9y6Xk')
        emailjs.sendForm('contact_service','default_contact', e.target)
        .then((result) => {
            console.log(result.text);
            alert.success("Email enviado com sucesso! Em breve entraremos em contato");
        }, (error) => {
            console.log(error.text);
            alert.error("Ops! Algo deu errado :(");
        }
      );
    }
    return (
        <>
            <div className="contact-stripe">
                <p className="contact-title">Entre em Contato</p>
            </div>
            <div className="contact-container" id="contact-link">
                <div className="contact-form-container">
                    <form action="" className="contact-form" id="contact-form" onSubmit={sendEmail}>
                        <input type="text" className="form-input" id="name-field" name="name" placeholder="Nome"/>
                        <div className="contact-info-container">
                            <input type="email" name="email" className="form-input" id="email-field" placeholder="Email"/>
                            <input type="phone" name="phone" className="form-input" id="tel-field" placeholder="Telefone"/>
                        </div>
                        <div className="message-container">
                            <label htmlFor="message" className="message-label">Mensagem</label>
                            <textarea name="message" id="" cols="30" rows="10" className="message"/>
                        </div>
                        <button type="submit" className="submit-form">Enviar</button>
                    </form>
                </div>
            </div>
        </>
    );
}
