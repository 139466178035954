import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider, positions, transitions} from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

const options = {
  position: positions.MIDDLE,
  transition: transitions.FADE,
  timeout: 5000
}

const Site = () => (
  <Provider template={AlertTemplate} {... options}>
    <App/>
  </Provider>  
);

ReactDOM.render(
  <React.StrictMode>
    <Site />
  </React.StrictMode>,
  document.getElementById('root')
);


